import { dateToMoment } from '.'
import { DateTime } from 'luxon'
import { round } from 'lodash'
/**
 * attempts to format a US telephone
 *
 * @param {*} value
 * @returns
 */
export const formatTelephone = value => {
  const numbers = value && value.replace(/-/g, '')
  const matches = numbers && numbers.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (matches) {
    return '(' + matches[1] + ') ' + matches[2] + '-' + matches[3]
  }

  return value
}

/**
 * short format date w/ optional timezone
 *
 * @param {*} date
 * @param {*} timezone
 */
export const formatDateShort = (date, timezone) =>
  dateToMoment(date, timezone).format('M[/]D[/]YY')

/**
 * short format time` w/ optional timezone
 *
 * @param {*} date
 * @param {*} timezone
 */
export const formatTimeShort = (date, timezone) =>
  dateToMoment(date, timezone).format('h:mm A')

/**
 * long format date
 *
 * @param {*} date
 * @param {*} timezone
 */
export const formatDateLong = (date, timezone) =>
  dateToMoment(date, timezone).format('ddd, MMMM D, YYYY')

/**
 * long format date & time w/ optional timezone
 *
 * @param {*} date
 * @param {*} timezone
 */
export const formatDateTimeLong = (date, timezone) =>
  dateToMoment(date, timezone).format('dddd, MMMM D, YYYY h:mm A (zz)')

/**
 * format a boolean as Yes/No string
 *
 * @param {*} bool
 * @returns string
 */
export const boolToYesNo = bool => {
  return bool && bool.toString() === 'true' ? 'Yes' : 'No'
}

export const formatCurrency = price => {
  if (price === null || price === undefined) {
    return ''
  }

  const numericPrice = Number(price)

  if (!isFinite(numericPrice)) {
    return ''
  }

  const formatted = numericPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return `$${formatted}`
}

export const formatPercentage = (n, precision = 2) => `${round(n * 100, precision)}%`

/**
 * escapeSpecialCharacters
 *
 * @param {*} str
 * @returns string with all special characters escaped
 */
export const escapeSpecialCharacters = str => {
  if (!str) {
    return ''
  }
  return str
    .replace(/\\n/g, '\n')
    .replace(/\\'/g, '\'')
    .replace(/\\"/g, '"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\r')
    .replace(/\\t/g, '\t')
    .replace(/\\b/g, '\b')
    .replace(/\\f/g, '\f')
}

/**
 * format address to a single-line string
 *
 * @param {*} { street, apartment, city, state, zip }
 */
export const formatAddress = ({ street, apartment, city, state, zip }) =>
  [street, apartment, city, state, zip].filter(o => !!o).join(', ')

export const formatAppointmentDate = (date, timezone) =>
  dateToMoment(date, timezone).format('dddd, MMMM D - h:mm a')

export const formatAppointmentServiceTitles = appointmentServices => {
  const serviceTitles = []

  appointmentServices.forEach(service => {
    let title = ''
    title += service.baseService?.name
    service.addOnServices.forEach(addOn => {
      title += ` + ${addOn.name}`
    })
    serviceTitles.push(title)
  })

  return serviceTitles.join(', ')
}

export const calculateAppointmentDuration = (start, end) =>
  DateTime.fromISO(end).diff(DateTime.fromISO(start), 'minutes').toObject()
    .minutes
export const formatGooglePlaceToAddress = location => {


  // confirm existence of street number, street name, and zip code
  const hasStreetNumber = location?.address_components?.some(component => component.types.includes('street_number'))
  const hasPostalCode = location?.address_components?.some(component => component.types.includes('postal_code'))
  const hasRoute = location?.address_components?.some(component => component.types.includes('route'))

  // not full address
  if (!hasStreetNumber || !hasPostalCode || !hasRoute) {
    return null
  }

  let { formatted_address } = location
  formatted_address = formatted_address.split(', ')
  return {
    street: formatted_address[0],
    city: formatted_address[1],
    state: formatted_address[2].split(' ')[0],
    zip: formatted_address[2].split(' ')[1]
  }
}
