import React from 'react'
import { mq } from '../helpers'
import { gsColors } from '../styles/Variables'
import styled from '@emotion/styled'

export const InputContainer = styled.div`
  position: relative;
  width: ${props => props.width ? props.width : '100%'};

  span {
    font-family: Euclid;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
  }

  input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: ${props => props.color ? props.color : '#000'};
    font-family: ${props => props.disabled ? 'EuclidItalic' : 'Euclid'};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.36px;
    border-bottom: ${props => props.error ? `1.5px solid ${gsColors.sunsetOrange}` : '1px solid #000'};
    padding: ${props => props.isCash ? '12px 0 12px 18px' : '12px 0'};
    z-index: 9998;
    border-radius: 0;


    &:focus {
      outline: none;
    }

    ${mq('max', '768px')} {
      font-size: 16px;
    }

    &:focus + label, &:not([value=""]) + label {
      top: 0;
      ${props => props.isCash ? 'left: 0 !important;' : ''}
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.22px;
    z-index: 1000;

    ${mq('max', '768px')} {
      font-size: 10px;
    }
  }

    ${props => props.isCash ? `
    &:not(:focus) {
      &::placeholder {
        color: transparent;
      }
    }    
    ` : ''}
  }

  label {
    left: ${props => props.isCash ? '18px' : '0'} !important;
    font-family: ${props => props.disabled ? 'EuclidItalic' : 'Euclid'};
    position: absolute;
    opacity: 0.5;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    transition: all .1s ease;
    z-index: -100;
  }
`

const Input = React.forwardRef(({ value, onChange, color, placeholder, disabled, width, isCash, error, autoComplete, ...props }, ref) => {

  if (isCash) {
    props = { ...props, type: 'number', step: 'any', min: '0', placeholder: '0.00' }
  }  
  
  const id = props.id ?? props.name

  return (
    <InputContainer {...{ color, disabled, width, isCash, error }}>
      {isCash && <span>$</span>}
      <input
        ref={ref}
        {...props}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete ?? 'off'}
      />
      <label htmlFor={props.name}>
        {placeholder}
      </label>
    </InputContainer>
  )
})

export default Input

// < input type = "number" step = "any" min = "0" placeholder = "0.00" value = "20" >
