import styled from '@emotion/styled'
import { gsColors as c } from '../../styles/Variables'
import { mq } from '../../helpers'

export const StickyNav = styled.div`
  position: fixed;
  top: 0;
  z-index: 777;
  width: 100vw; 

  .sticky-content {
    background-color: ${c.white};
    width: 100vw;
    height: 60px;
    transition: all 0.25s ease;
    opacity: 0;
    transform: translateY(-55px);
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    z-index: 1000;
  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 1600px;
    padding-left: 88px;
  }

  ${mq('max', '768px')} {
    display: none;
  }


  .show-sticky {
    transform: translateY(0);
    opacity: 1;
  }

  .gs-logo {

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 175px;
      
      img {
        width: 100%;
        object-fit: contain;
        z-index: 999;
      }
    }
  }

  button {
    height: 100%;
    width: 180px !important;

    color: var(--white-ffffff, #FFF);
    font-size: 14px !important;
    font-weight: 700;
    letter-spacing: 0.84px;
  }
`

export const SkipToContent = styled.button`
  opacity: 0;
  background-color: ${c.white};
  border: none;
  height: 70px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: brown;
  font-size: 1vw;
  position: absolute;
  top: ${props => (props.location === '/' ? '2.5rem' : '0')};
  left: 0;
  padding: 0 1rem;
  color: ${c.honeyFlower};
  transform: translateX(-100%);
  transition: transform 0.5s ease, opacity 0.5s ease;

  ${mq('max', '1015px')} {
    display: none;
  }

  &:focus {
    transform: translateX(0%);
    opacity: 1;
  }

  &:focus + div {
    transform: translateX(6vw);
  }
`

export const MainNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  z-index: 990;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  width: 100vw;
  align-items: center;


  ${mq('max', '768px')} {
    position: sticky;
    top: 0;
  }

  .mobile-dropdown {
    overflow: auto;
    position: absolute;
    left: 0;
    top: 68px;

    min-width: 346px;
    width: 80%;

    transform: translateX(-100%);
    transition: all 0.5s ease;
    
    height: calc(100vh - 68px);
    background-color: #FFF;
    z-index: 1;
    padding-top: 24px;
    
    &.active {
      transform: translateX(0%);
    }

    ${mq('min', '768px')} {
      display: none;
    }

    .mobile-nav-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      height: 100%;

      .inner-dropdown-button {
        border: none;
        background-color: transparent;
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
  
        p {
          font-family: Garnett;
          font-size: 25px;
          font-weight: 750;
          line-height: 138%;
          color: #000;
        }
  
        &:hover {
          svg {
            path {
              color: ${c.sunsetOrange} !important;
              fill: ${c.sunsetOrange} !important;
            }
          }
    
          p {
            color: ${c.sunsetOrange} !important;
          }
        }
      }

      .mobile-link {
        width: 100%;
        padding: 16px;
        display: flex;
        align-items: center;
        color: #000;
  
        font-family: Garnett;
        font-size: 25px;
        font-weight: 750;
        line-height: 138%;
        text-decoration: none;
  
        &:visited {
          color: #000;
        }
  
        &:hover {
          color: ${c.sunsetOrange} !important;
        }
      }
  
      .mobile-inner-dropdown {
        display: flex;
        flex-direction: column;

        button {
          all: unset;
        }
        
        a, button {
          padding-left: 40px;
          color: #000 !important;
          font-family: Euclid;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-decoration: none;
          padding: 10px 0 10px 40px;
  
          &:visited {
            color: #000;
          }
  
          &:hover {
            cursor: pointer;
            color: ${c.sunsetOrange} !important;
          }
        }
      }

      .lower-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 36px;
        width: 100%;    

        a {
          color: #000 !important;
          font-family: Euclid;
          font-size: 18px;
          font-weight: 700;
          line-height: 138%;
          padding: 14px 16px;
          width: 100%;
          text-decoration: none;

          &:visited {
            color: #000;
          }

          &:hover {
            color: ${c.sunsetOrange} !important;
          }
        }
      }
    }
  }

  .book-button {
    border: none;
    background-color: #FFF;
    padding: 0 0 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &.mobile {
      padding: 0 16px;
    }

    p {
      line-height: 24px;
      border-bottom: 2px solid black;
      transform: translateY(1px);

      ${mq('max', '768px')} {
        font-size: 14px !important;
      }
    }
    
    &:hover {
      p {
        color: ${c.sunsetOrange} !important;
        border-bottom: 2px solid ${c.sunsetOrange};
      }
    }
  }
`
export const NewNavContainerWeb = styled.div`
  display: flex;
  height: 92px;
  padding: 0px 88px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // z-index: 999;
  width: 100%;
  max-width: 1600px;

  ${mq('max', '1375px')} {
    padding: 0 80px;
  }

  ${mq('max', '915px')} {
    padding: 0px 30px;
  }

  ${mq('max', '815px')} {
    padding: 0px 16px;
  }

  ${mq('max', '768px')} {
    display: none;
  }

  .left-links {
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1000;

    .logo-link-mid {
      max-width: 185px;
      padding: 0 12px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;

      ${mq('max', '1375px')} {
        display: flex;
      }

      
      img {
        width: 100%;
        display: block;
        object-fit: contain;
      }
    }
  }


  .web-dropdown, .user-dropdown {
    position: absolute;
    top: 92px;
    display: flex;
    flex-direction: column;
    width: max-content !important;
    background-color: #FFF;
    box-shadow: inset 0 2px 4px -2px rgba(0,0,0,0.4), 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

    button {
      &.nav-item-dropdown {
        all: unset;
      }
    }

    .nav-item-dropdown, button.nav-item-dropdown {
      padding: 8px 40px;
      color: #000;
      font-family: Euclid;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration: none;

      &:visited {
        color: #000;
      }

      &:hover {
        cursor: pointer;
        color: ${c.sunsetOrange};
      }

      &.first {
        padding: 24px 40px 8px;
      }

      &.last {
        padding: 8px 40px 24px;     
      }
    }
  }

  .web-dropdown {
    left: 47px;

    ${mq('max', '1375px')} {
      left: 236px;
    }

    ${mq('max', '915px')} {
      left: 186px;
    }
  
    ${mq('max', '815px')} {
      left: 171px;
    }

    ${mq('max', '768px')} {
      display: none;
    }
  }

  .user-dropdown {
    right: 18px;

    ${mq('max', '1375px')} {
      right: 10px;
    }

    ${mq('max', '915px')} {
      right: 0;
    }
  }

  .logo-link-large {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 0;
    transform: translate(-50%, -50%);

    ${mq('max', '1375px')} {
      display: none;
    }
  }
  
  .right-links {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .services-button {
    border: none;
    background-color: #FFF;
    padding: 0 12px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    cursor: pointer;

    ${mq('max', '1375px')} {
      padding: 0 12px;
    }

    &:hover {
      svg {
        g {
          path {
            color: ${c.sunsetOrange} !important;
            fill: ${c.sunsetOrange} !important;
          }
        }
      }

      p {
        color: ${c.sunsetOrange} !important;
      }
    }

    p, svg {
      pointer-events: none;
    }
  }

  .nav-item {
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #000;
    font-family: Euclid;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.84px;
    text-decoration: none;
    padding: 0 12px;
    z-index: 1000;
    
    &:visited {
      color: #000;
    }
    
    &:hover {
      color: ${c.sunsetOrange};
    }

    &.first {
      padding: 0 12px 0 0 !important;
    }

    &.last {
      padding: 0 0 0 12px !important;
    }
  }
`

export const NewNavContainerMobile = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 999;

  a {
    color: #000 !important;
  }

  
  ${mq('min', '769px')} {
    display: none;
  }

  .menu-button {
    height: 100%;
    padding: 0 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;    
  }

  .logo-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit-contain;
      width: 100%;
    }
  }
  
`
