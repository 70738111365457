export const serviceUrls = [
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Packages',
    link: '/packages',
  },
  {
    name: 'Gifts',
    link: '/gift-card',
  },
  {
    name: 'Weddings',
    link: '/weddings',
  },
  {
    name: 'Events',
    link: '/events',
  }
]

export const supportUrls = [
  {
    name: 'FAQ',
    href: 'https://help.glamsquad.com/en-US'
  },
  {
    name: 'Terms',
    link: '/terms',
  },
  {
    name: 'Privacy',
    link: '/privacypolicy',
  },
  {
    name: 'Community Guidelines',
    link: '/community-guidelines',
  }
]

export const aboutUrls = [
  {
    name: 'Become a Beauty Pro',
    href: 'https://apply.glamsquad.com',
  },
  {
    name: 'How It Works',
    link: '/how-it-works',
  },
  {
    name: 'Press',
    link: '/press',
  },
  {
    name: 'Affiliate Program',
    link: '/affiliate',
  }
]


