import React, { useEffect, } from 'react'
import Head from 'next/head'
import GoogleAnalyticsTag from '../components/book/steps/components/GoogleAnalyticsTag'
import {
  createBugsnagErrorBoundary,
  segmentEvent,
  googleTagManagerEvent,
  segmentAnonymousId,
  segmentPageView,
  usePersistedReducer
} from '../helpers'
import { Global } from '@emotion/core'
import { MarketContextProvider } from '../components/MarketContext'
import { UserContextProvider } from '../components/UserContext'
import ExternalScripts from '../components/ExternalScripts'
import Polyfills from '../components/Polyfills'
import Footer from '../components/footer'
import GlobalStyles from '../styles/GlobalStyles'
import Nav from '../components/nav'
import ErrorPage from '../components/error-page'
import { useRouter } from 'next/router'
import { growthbook } from '../services/growthbook'
import { GrowthBookProvider } from "@growthbook/growthbook-react";

const appReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CODE':
      return { ...state, code: action.code }
    default:
      return state
  }
}

const initialState = {
  code: null
}

export const setCode = code => ({
  type: 'SET_CODE',
  code
})

export default function MyApp({ Component, pageProps }) {
  const { query, pathname } = useRouter()
  const { state, reducerDispatch: appDispatch } = usePersistedReducer(appReducer, initialState, 'app')

  useEffect(() => {
    segmentPageView(pathname)
  }, [pathname])

  useEffect(() => {
    growthbook.loadFeatures()
  }, [])

  useEffect(() => {
    if (!query.code
      || state.code
      || query.code === state.code
    ) {
      return
    }

    appDispatch(setCode(query.code))
  }, [query, state, appDispatch])

  useEffect(() => {
    googleTagManagerEvent('identify', { segmentAnonymousId: segmentAnonymousId() });
    segmentEvent('Web Opened');
  }, [])

  const AppErrorBoundary = createBugsnagErrorBoundary('App')
  const ExternalScriptsErrorBoundary = createBugsnagErrorBoundary('ExternalScripts')
  const PageErrorBoundary = createBugsnagErrorBoundary('Page')

  const pagePropsWithState = {
    ...pageProps,
    code: state.code,
    appDispatch,
    appState: state,
  }

  return (
    <>
      <Head>
        <title>
          Glamsquad | The on-demand, in-home beauty service in NYC, LA, SF, Miami, Dallas/Fort Worth, Houston & DC
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <AppErrorBoundary FallbackComponent={ErrorPage}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <GrowthBookProvider growthbook={growthbook}>
            <UserContextProvider>
              <MarketContextProvider>
                <Global styles={GlobalStyles} />
                <Nav />
                <main>
                  <PageErrorBoundary FallbackComponent={ErrorPage}>
                    {
                      Component.getLayout
                        ? Component.getLayout(<Component {...pagePropsWithState} />)
                        : <Component {...pagePropsWithState} />
                    }
                  </PageErrorBoundary>
                </main>
                <Footer />
              </MarketContextProvider>
            </UserContextProvider>
          </GrowthBookProvider>
        </div>
        <ExternalScriptsErrorBoundary>
          <ExternalScripts />
          <GoogleAnalyticsTag />
        </ExternalScriptsErrorBoundary>
        <Polyfills />
      </AppErrorBoundary>
    </>
  )
}

/**
 * called by next to report performance. we're sending it along to google analytics
 *
 * @export
 * @param {*} { id, name, label, value }
 */
export function reportWebVitals({ id, name, label, value }) {
  if (typeof window === 'undefined') {
    return
  }

  // send after delay to give gtag time to load
  setTimeout(() => {
    if (!window.gtag) {
      return
    }

    window.gtag('event', name, {
      event_category:
        label === ' ' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    })
  }, 1000);
}
