import * as React from 'react'

const PlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.667 10.333V2h-3.334v8.333H2v3.334h8.333V22h3.334v-8.333H22v-3.334h-8.333Z"
      clipRule="evenodd"
    />
  </svg>
)
export default PlusIcon
