/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useRef } from 'react'
import { StickyNav, MainNavContainer } from './styles'
import Link from 'next/link'
import Banner from '../banner'
import Button from '../Button'
import { useBanner } from '../../helpers'
import { gsColors as c } from '../../styles/Variables'
import { useRouter } from 'next/router'
import WebNavBar from './WebNavBar'
import MobileNavBar from './MobileNavBar'

// banners to display based on code provided
const codeToBannerMapping = {
  welcome20: { text: 'Get $20 Off Your First Appointment. Use Code: WELCOME20', link: '/book' },
}

// forcedShow for booking flow confirmation, but disabled in all over booking flow steps
const Nav = ({ forcedShow = false }) => {
  let banner = useBanner()

  const router = useRouter()
  const { pathname, query } = router

  if (codeToBannerMapping[query?.code?.toLowerCase()]) {
    banner = codeToBannerMapping[query?.code?.toLowerCase()]
  }

  if (banner?.link) {
    banner.func = () => router.push(banner.link)
  }

  // set up the sticky nav which is displayed after the nav bar scrolls offscreen
  // used for detecting if navbar is off viewport to show sticky nav
  const navContainerRef = useRef()
  const [isStickyNavVisible, setStickyNavVisible] = useState(false)

  // mobile nav state
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false)
  const toggleMobileDropdown = () => setMobileDropdownOpen((o) => !o)

  // const toggleMobileNav = () => setMobileNavVisible(!mobileNavVisible)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    if (!window.IntersectionObserver) {
      return
    }
    // checks if ref is truthy because navbar is not rendered on some pages, throws reference error because ref is null
    if (!navContainerRef.current) {
      return
    }

    let timeoutId
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      // intersecting is false navbar is out of viewport
      if (!entry.isIntersecting) {
        setStickyNavVisible(true)
        //   timeoutId = setTimeout(() => {
        //     setStickyNavVisible(true)
        //   }, 1000)
      } else {
        // cancel timeout if we were about to show nav
        if (timeoutId) {
          clearTimeout(timeoutId)
          timeoutId = undefined
        }
        setStickyNavVisible(false)
      }
    })
    intersectionObserver.observe(navContainerRef.current)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [navContainerRef, pathname])

  // closes navbar and sticky nav when page changes
  useEffect(() => {
    setMobileDropdownOpen(false)
    setStickyNavVisible(false)
  }, [pathname])

  // Some pages have a custom navbar - no not render on those pages.
  if (
    !forcedShow && 
    (pathname.includes('book') ||
    pathname.includes('become-a-pro') ||
    pathname.includes('referrals') ||
    pathname.includes('appointment-survey'))
  ) {
    return null
  }

  // will add back, not a V1 re-brand feature
  // const skipToContentFunction = () => {
  //   const mainContent = document.querySelector('#main-content')
  //   if (mainContent) {
  //     mainContent.focus()
  //   }
  // }

  const bookLink = () => router.replace('/book')

  return (
    <>
      <StickyNav>
        <div
          className={`${isStickyNavVisible ? 'show-sticky' : ''} sticky-content`}
        >
          <div
            className={`content-container ${isStickyNavVisible ? '' : 'hide'}`}
          >
            <div className="gs-logo">
              <Link legacyBehavior href="/">
                <a>
                  <img
                    src="/images/new-gs-wordmark-logo.svg"
                    width="320" height="44"
                    alt="Glamsquad logo"
                  />
                </a>
              </Link>
            </div>
            <Button hover color={c.sunsetOrange} path="/book">
              Book Now
            </Button>
          </div>
        </div>
      </StickyNav>
      {(banner?.text && banner?.link) && (
        <Banner navigationFunc={banner?.func}>
          <Link href={banner.link}>
            {banner.text}
          </Link>
          {(banner?.secondaryText && banner?.secondaryText?.length) && (
            <a className='secondary'>{banner.secondaryText}</a>
          )}
        </Banner>
      )}
      <MainNavContainer ref={navContainerRef}>
        <WebNavBar {...{ bookLink }} />
        <MobileNavBar
          {...{ bookLink, mobileDropdownOpen, toggleMobileDropdown }}
        />
      </MainNavContainer>
    </>
  )
}

export default Nav
