import * as React from 'react'

const UpArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9.414 4.414 8 3 6.586 4.414 1 10l1.414 1.414L8 5.83l5.586 5.585L15 10 9.414 4.414Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .53h16v11H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default UpArrow
