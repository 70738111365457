import React from 'react'
import { Button as Btn } from '../styles/Components'
import LoadingDots from '../components/book/steps/components/LoadingDot'
import { useRouter } from 'next/router'
import { gsColors } from '../styles/Variables'

const Button = props => {
  const router = useRouter()
  if (props.path) {
    const redirect = () => router.push(props.path)
    return (
      <Btn onClick={redirect} {...props}>
        {props.working ? <LoadingDots color={props.loadingColor ? props.loadingColor : gsColors.white} /> : props.children}
      </Btn>
    )
  } else {
    return (
      <Btn {...props}>
        {props.working ? <LoadingDots color={props.loadingColor ? props.loadingColor : gsColors.white} /> : props.children}
      </Btn>
    )
  }
}

export default Button
