import React from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import { gsColors } from '../../../../styles/Variables';

const LoadingDotDiv = styled.div`
  animation: pulse 1s infinite;
  background-color: ${props => props.color};
  border-radius: 50%;
  transform: scale(1);
  transform-origin: center;
  margin: ${props => props.margin};
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 ${props => Color(props.color).alpha(0.7).string()};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px ${props => Color(props.color).alpha(0).string()};
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 ${props => Color(props.color).alpha(0).string()};
    }
  }
`;

export default function LoadingDot({ color = gsColors.venus, margin = '0 auto', size = 20 }) {
  return <LoadingDotDiv {...{ color, margin, size }} />;
};
