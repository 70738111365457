import { useEffect } from 'react'

const GOOGLE_ANALYTICS_KEYS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY
const GOOGLE_OPTIMIZE_ID = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID

const GoogleAnalyticsTag = () => {  

  // setup google analytics
  useEffect(() => {

    const googleAnalyticsKeys = GOOGLE_ANALYTICS_KEYS.split(',')

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKeys[0]}`;
    script.async = true;
    document.body.appendChild(script);  
    
    // configure for each key
    let gtagConfig = googleAnalyticsKeys.map((googleAnalyticsKey) => `
      gtag('config', '${googleAnalyticsKey}', { 'groups': 'default', 'optimize_id': '${GOOGLE_OPTIMIZE_ID}', 'linker': { 'accept_incoming': true } });
    `).join('\n')
    

    const initScript = document.createElement('script');
    initScript.innerText = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      ${gtagConfig}

      gtag('require', 'displayfeatures');
      gtag('require', 'ecommerce');
      
      window.dataLayer.push({ event: 'optimize.activate' });
    `;
    document.body.appendChild(initScript);
  }, []);


  return null;
}

export default GoogleAnalyticsTag