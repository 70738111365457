import styled from '@emotion/styled';
import { gsColors as c } from '../../../styles/Variables';
import { mq } from '../../../helpers'

export const BannerWrapper = styled.div`
  width: 100vw;
  height: 60px;
  background-color: ${c.cobalt};
  display: flex;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  
  & > div{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;    
  }

  a {
    color: #FFF;
    text-decoration: none;

    text-align: center;
    font-family: Euclid;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.96px;

    &.secondary {
      font-weight: 400;
    }

    ${mq('max', '600px')} {
      font-size: 85%;
      max-width: 70%;
    }
  }
`