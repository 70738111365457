export const gsColors = {
  black: '#000000',
  ebb: '#E9E2E1',
  facebook: '#3b5998',
  grape: '#52215B',
  hintOfRed: '#F7F5F5',
  honeyFlower: '#631E71',
  darkEggplant: '#37173d',
  mineShaft: '#3f3f3f',
  paleSlate: '#c2b9bf',
  persianGreen: '#00B497',
  prim: '#F4EBF2',
  provincialPink: '#fef7f2',
  saltBox: '#746D76',
  seance: '#822297',
  sunsetOrange: '#F75D51',
  tangerineHover: '#F6938A',
  venus: '#9c969a',
  white: '#FFFFFF',
  coral: '#faf9f9',
  cobalt: '#07218F',
  salmon: '#F7CBC5',
  backgroundSalmon: '#f1cbc4',
  khaki: '#F9F4EE',
  meredithGrey: '#878787',
  greyPoupon: '#BEBFC0',
  textGrey: '#C7C7C7',
  lightTangerine: '#E8E2E1',
  greyBelowHero: '#E8E2E180',
};
