import React from 'react'
import PhoneInput from 'react-phone-number-input'
import Input from './Input'
import styled from '@emotion/styled'
import 'react-phone-number-input/style.css'

const PhoneInputWrapper = styled.div`
  .PhoneInputCountry {
    border-bottom: 1px solid #E8E2E1 !important;

    .PhoneInputCountryIcon--border {
      box-shadow: none;
    }

    .PhoneInputCountrySelectArrow {
      margin: 0 10px 0 15px;
    }
  }
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-left: 10;

  label {
    color: #000;
    opacity: 0.5;
    position: absolute;
    font-family: 'Euclid';
    font-size: 18px;
    // z-index: -1;
    top: 10px;
    transition: all .1s ease;

  }
  .active {
    font-size: 11px;
    top: -2px;
    left: 0;
    line-height: 120%;
    letter-spacing: 0.22px;
  }

  input {
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #E8E2E1 !important;
    font-size: 18px;
    font-family: Euclid;
    line-height: 120%;
    border-radius: 0;
    letter-spacing: 0.36px;
    padding: 12px 0;
    position: relative;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
`

const PhoneNumberInput = (props) => {
  return (
    <PhoneInputWrapper>
      <PhoneInput
        defaultCountry='US'
        international
        inputComponent={CustomInput}
        {...props}
      />
    </PhoneInputWrapper>
  )
}

/*
 * CustomInput overrides PhoneInput input component with our styled one
 * pass state prop to PhoneNumberInput which gets passed to CustomInput
 * state: { active, value, error }
*/
const CustomInput = React.forwardRef((props, ref) => {
  const isActive = !!props?.value

  return (
    <InputContainer>
      <Input
        ref={ref}
        {...props}
      />
      { !props?.hideLabel && (
        <label className={(isActive) ? 'active' : ''}>Phone Number</label>
      )}
    </InputContainer>
  )
})

export default PhoneNumberInput
