import moment from 'moment-timezone'
import axios from 'axios'
import { DateTime, IANAZone } from 'luxon'
import { find, minBy } from 'lodash'

/**
 * create moment from date and set timezone if provided
 *
 * @param {*} date
 * @param {*} timezone
 * @returns
 */
export const dateToMoment = (date, timezone = 'America/New_York') => {
  // if number, assume it's unix seconds
  if (typeof date === 'number') {
    date = moment.unix(date)
  }
  date = moment(date)
  if (timezone) {
    date = date.tz(timezone)
  }
  return date
}

/**
 * Matches save addresses within me with address in state
 *
 * @param {*} me
 * @param {*} { selectedAppointment, address, step }
 * @param {*} dispatch
 * @returns
 */
export const syncSavedAddressWithState = (
  me,
  { selectedAppointment, address, step },
  dispatch
) => {
  if (me && me?.addresses && address?.id === '') {
    // check if we already have an existing saved address which matches the one we entered
    const existingAddress = find((me.addresses.filter(a => !!a.marketId)), {
      latitude: address.latitude,
      longitude: address.longitude
    })

    if (!existingAddress) {
      return
    }

    // copy some fields we've already entered to the existing address so they're not lost
    if (address.type) {
      existingAddress.type = address.type
    }
    if (address.pets) {
      existingAddress.pets = address.pets
    }

    // if user got to step 4 without inputting notes / apartment, overwrite saved addr with empty.
    if (step > 3) {
      if (address.apartment) {
        existingAddress.apartment = address.apartment
      }
      if (address.notes) {
        existingAddress.notes = address.notes
      }
    }

    // set our existing address to be the one we're booking at
    dispatch({ address: existingAddress })
  }

  // ensure appointment has same address we have selected (and not a previous temporary address)
  if (selectedAppointment && selectedAppointment.addressId !== address.id) {
    dispatch({ selectedAppointment: { addressId: address.id } })
  }
}

/**
 * copies provided text to clipboard
 *
 * @param {*} text
 */
export const copyToClipboard = text => {
  // create a temporary element and set its text to value
  const element = document.createElement('textarea')
  element.value = text
  element.setAttribute('readonly', '')

  // position offscreen and add to documents
  element.style.position = 'absolute'
  element.style.left = '-9999px'
  document.body.appendChild(element)

  // select and copy
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
}

/**
 * given some text, return an alpha has of contents
 *
 * ripped from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 *
 * @param {*} text
 */
export const createDigest = text => {
  function bufferToHexString(buffer) {
    const byteArray = new Uint8Array(buffer)

    const hexCodes = [...byteArray].map(value => {
      const hexCode = value.toString(16)
      const paddedHexCode = hexCode.padStart(2, '0')
      return paddedHexCode
    })

    return hexCodes.join('')
  }

  function digestMessage(message) {
    const encoder = new TextEncoder()
    const data = encoder.encode(message)
    return window.crypto.subtle.digest('SHA-256', data)
  }

  return digestMessage(text).then(bufferToHexString)
}

export const mq = (condition, value) => {
  let mqString = ''

  switch (condition) {
    case 'min':
      mqString = `@media (min-width: ${value})`
      break
    case 'max':
      mqString = `@media (max-width: ${value})`
      break
    default:
      break
  }

  return mqString
}

/**
 * shiftDate: returns a new javascript date which has been shifted by the time offset between
 * the local time zone and the provided.
 *
 * This is a kludge used to deal with the ReactDatePicker's lack of time zone support.
 *
 * @param {Luxon or JS Date} date
 * @param {string} timezone
 * @param {number} [direction=1] direction to shift
 * @returns
 */
export const shiftDate = (date, timezone, direction = 1) => {
  date = !date.toSeconds ? DateTime.fromJSDate(date) : date

  // convert actual time into legacy ny-shifted time
  const marketOffset = new IANAZone(timezone).offset(date)
  const localOffset = DateTime.local().zone.offset(date)
  const offset = (localOffset - marketOffset) * direction

  return date.plus({ minutes: offset }).setZone(timezone).toJSDate()
}

/**
 * first two sets of coords are for the destination
 * second set is where the client is
 *
 *
 * @param {float} lat
 * @param {float} long
 * @param {float} clientLat
 * @param {float} clientLong
 */
export const calcDisplacement = (lat, long, clientLat, clientLong) => {
  const p = 0.017453292519943295
  const c = Math.cos
  const a =
    0.5 -
    c((lat - clientLat) * p) / 2 +
    (c(clientLat * p) * c(lat * p) * (1 - c((long - clientLong) * p))) / 2
  const distKm = 12742 * Math.asin(Math.sqrt(a))
  return distKm
}

/**
 * setMarketFromBrowserLocation: find and select closest from provided list of markets
 *
 * @param {markets[]} markets list of markets
 * @param {function} setMarket function to call with closest market
 */
export const setMarketFromBrowserLocation = (markets, setMarket) => {
  try {
    if (!markets?.length) {
      return
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          markets.forEach(market => {
            const distKm = calcDisplacement(
              market.addressCompletionOptions.lat,
              market.addressCompletionOptions.long,
              latitude,
              longitude
            )

            if (distKm < 100) {
              setMarket(market)
            }
          })
        },
        () => { }
      )
    }
  } catch (error) {
    console.warn('setMarketFromBrowserLocation error', error)
  }
}

export const validateEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
export const validateZip = zip => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)
export const validatePhone = phone =>
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)

const stringContainsNumber = string => /\d/.test(string)

export const validateFirstOrLast = string => {
  if (!string.length || string.length > 50) {
    return false
  }
  if (stringContainsNumber(string)) {
    return false
  }
  return true
}

/**
 *
 * Add this object in the format below to any <img> or <Image> tag
 * to prevent staggered top-to-bottom load
 *
 * example:
 *
 * <img src={imageSource} alt='hero' {...smoothLoad} />
 */
export const smoothLoad = {
  style: { opacity: 0, transition: 'opacity .5s ease' },
  onLoad: ({ target }) => (target.style.opacity = 1)
}

export const getRemainingHours = appointment => {
  let currentTime = new Date().getTime()
  let appointTime = new Date(appointment.actualStartDateTime).getTime()
  let hourDiff = appointTime - currentTime //in ms
  let hDiff = hourDiff / 3600 / 1000 //in hours
  return Math.floor(hDiff)
}


export const generateGiftCardFinePrint = (option, groups, selectedGroup) => {
  if (option === 'gift-card') {
    return `E-gift cards can be applied to any service. 
    We'll add tax & gratuity to the price before checkout 
    so that the recipient can apply your gift to their tax & 
    gratuity as well.`
  } else {

    if (groups && groups[selectedGroup]) {
      return `We'll add tax & gratuity to the price before checkout.`
    } else {
      return 'Sorry, there are no packages available for this location'
    }
  }
}


/**
 * generate display price for cards on Weddings and Services pages.
 * accounts for informational services / static wedding service data
 * @param {object} marketService marketService object with service attached
 * @param {object} market current user market selected
 * @returns {string|null} price string or null if no price
 */
export const generateServiceCardPrice = (marketService, market) => {
  if (!marketService?.service) {
    return null
  }

  const getPrice = (price) => {
    if (Array.isArray(price)) {
      switch (market?.shortName) {
        case 'HPN':
          return price[1]
        case 'LVN':
          return price[2]
        default:
          return price[0]
      }
    } else if (typeof price === 'string') {
      return price
    } else if (typeof price === 'number') {
      return `$${price}`
    }
    return null
  }

  return getPrice(
    marketService.service.isInformational
      ? marketService.service.informationalPriceText
      : marketService.price
  )
}

/**
 * looks up user's approximate location based on IP address and sets
 * closest market, regardless of matching a zone.  If it can't do this for
 * any reason, default to NY
 * @param {array} markets array of SB markets
 * @param {function} setSelectedMarket wrapper function that saves market in state and local storage
 * @returns 
 */
export const findClosestMarketFromIp = async (markets, setSelectedMarket) => {
  if (!markets || !markets.length || !setSelectedMarket) {
    return
  }

  if (!process.env.NEXT_PUBLIC_IP_DATA_API_KEY) {
    setSelectedMarket(markets[0])
  }

  try {
    // looks up location info based on IP address making the request
    const data = await loadIpInfo()

    const latitude = data?.latitude
    const longitude = data?.longitude

    // set default if we don't have coords
    if (!latitude || !longitude) {
      setSelectedMarket(markets[0])
      return
    }

    // find closest market, regardless of zones
    const closestMarket = minBy(markets, (m) => {
      if (!m.latitude || !m.longitude || !latitude || !longitude) {
        return
      }

      const xDiff = Math.abs(latitude - m.latitude)
      const yDiff = Math.abs(longitude - m.longitude)
      const distance = Math.sqrt(xDiff ** 2 + yDiff ** 2)

      return distance
    })

    // set closest market or default if not found for some reason
    if (closestMarket) {
      setSelectedMarket(closestMarket)
    } else {
      setSelectedMarket(markets[0])
    }
  } catch (e) {
    console.error('error finding market', e)

    // set default market in the event of an error
    if (markets && markets.length && setSelectedMarket) {
      setSelectedMarket(markets[0])
    }
  }
}

let ipInfo; 

/**
 * loadIpInfo: fetches and caches IP info from ipdata.co
 *
 * @return {*} 
 */
export const loadIpInfo = async () => {
  if (!ipInfo) {
    const { data } = await axios.get(`https://api.ipdata.co?api-key=${process.env.NEXT_PUBLIC_IP_DATA_API_KEY}`)
    ipInfo = data
  }

  return ipInfo
}

/**
 * my-appointments view— determine if we should display the BP name below service.
 * only display pro if accepted, pick-a-pro, or in the past.
 * hide pro if un-accepted or no provider attached
 * @param {object} appointmentService appointmentService object
 * @returns {boolean}
 */
export const shouldDisplayProvider = (appointmentService) => {
  if (!appointmentService.providerId) {return false}

  if (
    (!appointmentService.isSquadSearch && !appointmentService.providerConfirmedAt) &&
    moment(moment()).isBefore(appointmentService.startDateTime)
  ) {
    return false
  }

  return true
}

/**
 * formats a service name for display in the survey
 * stolen from mantis
 * @param {*} appointmentService 
 * @returns {string}
 */
export const formatServiceName = (appointmentService) => {
  return appointmentService.baseService?.name + appointmentService.addOnServices?.map((addOnService) => ` + ${addOnService.name}`).join('')
}

export const getProInfoFromQuestions = (questions) => {
  for (let i = 0; i < questions.length; i++) {
    if (questions[i]?.nameFirst && questions[i]?.nameLast) {
      return {
        proPhoto: questions[i]?.proPhoto || '/images/blank-profile-picture.png',
        proName: `${questions[i].nameFirst} ${questions[i].nameLast.slice(0, 1)}.`,
        providerTitle: questions[i]?.providerTitle || 'Stylist'
      }
    }
  }
  return null
}

export const formatNewSurveyQuestion = (question, singleService) => {
  switch (question?.type) {
    case 'STAR':
      return {
        title: 'Overall Experience',
        text: singleService ? 'Pro friendliness, efficiency and your satisfaction.' : 'Rate the collective experience of multiple beauty experts during your appointment'
      }
    case 'BOOST':
      return {
        title: 'Quality of service',
        text: 'Skill and expertise demonstrated.'
      }
    case 'TIME':
      return {
        title: 'Timeliness',
        text: `Beauty Pro's punctuality.`
      }
    case 'TEXT':
      return {
        title: 'Thanks! Any more feedback?',
        text: ''
      }
    default:
      return {
        title: question?.value,
        text: ''
      }
  }
}

export const newSurveyParseOverallScreenData = (questions) => {
  let overallQuestion
  const providers = {}
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].type === 'STAR') {
      overallQuestion = questions[i]
    }
    const { nameFirst, nameLast, proPhoto, providerTitle } = questions[i]

    if (
      questions[i].providerTitle &&
      nameFirst &&
      nameLast &&
      !providers[`${nameFirst} ${nameLast}`]
    ) {
      providers[`${nameFirst} ${nameLast}`] = {
        proName: `${nameFirst} ${nameLast.slice(0, 1)}.`,
        proPhoto,
        providerTitle,
      }
    }
  }

  return {
    overallQuestion,
    providers: Object.values(providers),
  }
}

export const newSurveyParseMultiServiceScreenData = (questions) => {
  questions = questions.filter((question) => question.type !== 'STAR')

  return questions.reduce((a, b) => {
    if (b.type === 'TEXT') {
      a['feedback'] = [b]
      return a
    }
    if (a[b.appointmentServiceId]) {
      b.type === 'BOOST'
        ? a[b.appointmentServiceId][0] = b
        : a[b.appointmentServiceId][1] = b
    } else {
      a[b.appointmentServiceId] = []
      b.type === 'BOOST'
        ? a[b.appointmentServiceId][0] = b
        : a[b.appointmentServiceId][1] = b
    }
    return a
  }, {})
}