import React from 'react'
import { BannerWrapper } from './styles'

const Banner = (props) => {
  return (
    <BannerWrapper role='button' onClick={props.navigationFunc}>
      {/* packages page UI is affected by this banner— ID is necessary for a hacky solution */}
      <div id='banner' style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        {props.children}
      </div>
    </BannerWrapper>
  )
}

export default Banner