import React, { useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { exchangeCodeForToken, } from '../services/Authenticator'
import { isAuthenticated, signOut, me } from '../services'
import {
  segmentIdentify,
  segmentAnonymousId,
  setBugsnagUser,
  googleTagManagerEvent
} from '../helpers'
import { growthbook } from '../services/growthbook'

export const UserContext = React.createContext({})
export const useUserContext = () => useContext(UserContext)

export const UserContextProvider = ({ children, initialUser = undefined }) => {
  const { query } = useRouter()
  const { magic } = query

  const [user, _setUser] = useState(initialUser)


  // update state and identify to bugsnag & segment
  const setUser = (user) => {
    _setUser(user)

    // identifies a user to segment and bugsnag
    if (user) {
      try {

        segmentIdentify(user.id, { 
          id: user.id,      
          email: user.email, 
          phone: user.phone, 
          fullName: user.fullName,
          firstName: user.nameFirst, 
          lastName: user.nameLast,
          name: user.fullName,
        })
        
        setBugsnagUser(user)
        googleTagManagerEvent('identify', {
          userId: user.id,
          email: user.email,
          segmentAnonymousId: segmentAnonymousId(),
        })
        growthbook.setAttributes({
          id: user.id,
          firstName: user?.nameFirst,
          lastName: user?.nameLast,
          email: user?.email,
        })
      } catch (error) {
        console.warn(error, 'identify user')
      }
    }
  }

  // sign user in if given a magic code
  useEffect(() => {
    if (!magic) {
      return
    }
    exchangeCodeForToken(magic)
      .then(setUser)
  }, [magic])

  // function to reload the user from starbase
  const refreshUser = () => {
    if (!isAuthenticated()) {
      return
    }

    me()
      .then(user => setUser({ ...user }))
      .catch(err => {
        console.error(err)

        // sign out if error fetching user
        if (process.env !== 'development') {
          signOut()
        }
      })
  }

  // load user if we're authenticated
  useEffect(() => {
    refreshUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser, refreshUser }}>
      {children}
    </UserContext.Provider>
  )
}
