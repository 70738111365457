import React from 'react'
import { Header1, Header2, LinkButton } from '../styles/Components'
import styled from '@emotion/styled'
import { mq } from '../helpers'

export const ErrorWrapper = styled.div`
  height:100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  img {
    max-width: 400px;
    height: auto;
    margin-bottom: .5rem;
    transform: translateX(16px);

    ${mq('max', '416px')} {
      width: 100%;
      margin: 0 1rem .5rem;
    }
  }

  h2, a {
    margin: .5rem 0;
  }
`

const ErrorPage = ({ showHeader }) => {
  return (
    <ErrorWrapper>
      {showHeader && (<>
        <Header1>We bring beauty to you.</Header1>
        <Header2>In-home hair, makeup and nail services. Anytime, anywhere.</Header2>
      </>)}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src='./images/bug_fixing.svg' alt='An error has occurred' />
      <Header2 align="center">An error has occurred.</Header2>
      <LinkButton href="/">Refresh Glamsquad</LinkButton>
    </ErrorWrapper>
  )
}

export default ErrorPage


