/* eslint-disable indent */
import { reduce, get, keyBy, round } from 'lodash'
import { formatTimeShort } from './format'
import { DateTime } from 'luxon'

/**
 * placesResponseToAddress: converts a response from google places into an address object
 *
 * @param {*} place
 * @returns
 */
export const placesResponseToAddress = (place) => {
  if (!(place && place.address_components)) {
    return
  }

  // confirm existence of street number, street name, and zip code
  const hasStreetNumber = place.address_components?.some(component => component.types.includes('street_number'))
  const hasPostalCode = place.address_components?.some(component => component.types.includes('postal_code'))
  const hasRoute = place.address_components?.some(component => component.types.includes('route'))

  // not full address
  if (!hasStreetNumber || !hasPostalCode || !hasRoute) {
    return
  }

  const addressComponents = reduce(
    place.address_components,
    (acc, value) => ({
      ...acc,
      [value.types[0]]: value.long_name,
    }),
    {}
  )
  const address = {
    formatted: place.formatted_address,
    city: (
      addressComponents.sublocality_level_2
      || addressComponents.sublocality_level_1
      || addressComponents.locality
    ),
    state: addressComponents.administrative_area_level_1,
    zip: addressComponents.postal_code,
    latitude: round(place.geometry.location.lat(), 7),
    longitude: round(place.geometry.location.lng(), 7)
  }
  address.street = addressComponents.street_number ? `${addressComponents.street_number} ${addressComponents.route}` : `${addressComponents.route}`
  return address
}

/**
 * serviceAndAddOnServiceNames: converts a service and add-on ids into names
 *
 * @param {*} marketServices
 * @param {*} { serviceId, addOnServiceIds }
 * @returns
 */
export const serviceAndAddOnServiceNames = (marketServices, { serviceId, addOnServiceIds }, marketId = null) => {
  if (!marketServices?.length) {
    return {}
  }
  
  if (marketId) {
    marketServices = marketServices.filter(ms => ms?.marketId === marketId)
  }

  const marketServicesByServiceId = keyBy(marketServices, 'service.id')

  if (!marketServicesByServiceId[serviceId]) {
    return {} // return an empty object if we're not loaded yet
  }

  const {
    service: { name: serviceName },
    addOnMarketServices,
  } = marketServicesByServiceId[serviceId]

  const addOnMarketServicesByServiceId = keyBy(addOnMarketServices, 'service.id')

  return {
    serviceName,
    addOnServiceNames: addOnServiceIds.map(addOnServiceId =>
      get(addOnMarketServicesByServiceId, [addOnServiceId, 'service', 'addOnName']))
  }
}

/**
 * serviceAndAddOnServiceInfo: converts a service and add-on ids into names
 *
 * @param {*} marketServices
 * @param {*} { serviceId, addOnServiceIds }
 * @returns
 */
export const serviceAndAddOnServiceInfo = (marketServices, { baseServiceId, addOnServiceIds = [] }) => {
  const marketServicesByServiceId = keyBy(marketServices, 'service.id')

  const marketService = marketServicesByServiceId[baseServiceId]

  if (!marketService) {
    return {} // return an empty object if we're not loaded yet
  }

  const {
    price,
    addOnPrice,
    duration,
    addOnDuration,
    service: {
      name,
      addOnName,
    },
    addOnMarketServices,
  } = marketService

  const addOnServices = addOnServiceIds.map(addOnServiceId => serviceAndAddOnServiceInfo(addOnMarketServices, { baseServiceId: addOnServiceId }))

  // normalize returned add-on and base service attributes
  return {
    name: addOnName || name,
    price: addOnPrice || price,
    duration: addOnDuration || duration,
    addOnServices,
  }
}

/**
 * if no availability results, check if requests are for multiple services
 * @param {array<serviceRequest>} serviceRequests { addOnServices: [], backToBack: boolean, instances: number, serviceId: string }
 * @returns {boolean}
 */
export const hasMultiServiceRequests = (serviceRequests) => {
  let id = serviceRequests[0].serviceId
  for (let i = 1; i < serviceRequests.length; i++) {
    if (serviceRequests[i].serviceId !== id) {
      return true
    }
  }
  return false
}

/**
 * formats the retry availability search button
 * @param {array<serviceRequest>} originalRequests all service requests
 * @param {array<serviceRequest>} validRequests filtere requests that are available
 * @param {array<marketService} marketServices all market services
 * @returns string— "Try again without x and x"
 */
export const formatBookingRetryButton = (originalRequests, validRequests, marketServices) => {

  let message = 'Try Again Without '

  const withoutString =
    originalRequests
      .filter(sr => !validRequests.find(vsr => vsr.serviceId === sr.serviceId))
      .map(s => {
        return marketServices.find(ms => ms.service.id === s.serviceId).service.name
      })

  switch (withoutString.length) {
    case 1:
      return message + withoutString[0]
    case 2:
      return message + `${withoutString[0]} and ${withoutString[1]}`
    default:
      return message + `${withoutString.slice(0, -1).join(', ')} and ${withoutString.slice(-1)}`
  }
}

/**
 * returns an array representing the arrival window for an appointment
 * appends 15 minutes to startDateTime
 * @param {string} startDateTime
*/
export const getArrivalWindow = (startDateTime, timezone) => {
  const arrival = formatTimeShort(startDateTime, timezone)
  const endDateTime = DateTime.fromSeconds(startDateTime).plus({ minutes: 15 }).toISO()
  const end = formatTimeShort(endDateTime, timezone)

  return [arrival, end]
}

export const getAppointmentEndTime = (appointment, timezone) => {
  const serviceStartTimes = appointment.appointmentServices.map(service => service.startDateTime)
  const latestServiceTime = Math.max(...serviceStartTimes)

  const lastService = appointment.appointmentServices.find(service => service.startDateTime === latestServiceTime)
  const lastServiceEnd = DateTime.fromSeconds(lastService.startDateTime).plus({ minutes: lastService.duration + 15 }).toISO()

  return formatTimeShort(lastServiceEnd, timezone)
}
