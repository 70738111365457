import * as React from 'react'

const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6.586 10 8 11.414 9.414 10 15 4.414 13.586 3 8 8.586 2.414 3 1 4.414 6.586 10Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .53h16v11H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default DownArrow
