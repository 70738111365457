/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useUserContext } from '../UserContext'
import { signOut as _signOut } from '../../services/Authenticator'
import { NewNavContainerWeb } from './styles'
import { Text } from '../../styles/Components'
import UpArrow from './UpArrow'
import DownArrow from './DownArrow'

const WebNavBar = ({ bookLink }) => {
  const [webDropdownOpen, setWebDropdownOpen] = useState(false)
  const [userDropdownOpen, setUserDropdownOpen] = useState(false)

  const { user, setUser } = useUserContext()

  const toggleWebDropdown = () => setWebDropdownOpen((o) => !o)
  const toggleUserDropdown = () => setUserDropdownOpen((o) => !o)

  useEffect(() => {
    const clickAway = (e) => {
      if (e.target.id === 'dropdown-toggle') {
        return
      }
      if (webDropdownOpen && e.target.id !== 'dropdown-area') {
        toggleWebDropdown()
      }
    }
    window.addEventListener('click', clickAway, { passive: true })
    return () => window.removeEventListener('click', clickAway)
  }, [webDropdownOpen])

  useEffect(() => {
    const clickAway = (e) => {
      if (e.target.id === 'user-dropdown-toggle') {
        return
      }
      if (userDropdownOpen && e.target.id !== 'user-dropdown-area') {
        toggleUserDropdown()
      }
    }
    window.addEventListener('click', clickAway, { passive: true })
    return () => window.removeEventListener('click', clickAway)
  }, [userDropdownOpen])

  const signOut = () => {
    _signOut()
    setUser(undefined)
    window.location = '/'
  }

  const webDropdownItems = [
    { name: 'Book a Service', link: '/book'},
    { name: 'How It Works', link: '/how-it-works'},
    { name: 'Our Services', link: '/services'},
    { name: 'Packages', link: '/packages'},
    { name: 'Gift Cards', link: '/gift-card'},
  ]

  return (
    <>
      <NewNavContainerWeb>

        <div className="left-links">
          <Link href='/'>
            <span className='logo-link-mid'>
              <img src='/images/new-gs-wordmark-logo.svg' alt='glamsquad logo' />
            </span>
          </Link>
          <button
            className="services-button"
            onClick={toggleWebDropdown}
            id="dropdown-toggle"
          >
            <Text fontSize={14} spacing={0.84} weight={700} margin="0 4px 0 0">
              Services
            </Text>
            {webDropdownOpen ? <UpArrow /> : <DownArrow />}
          </button>

          { webDropdownOpen && (
            <div className='web-dropdown'>
              {
                webDropdownItems.map((item, idx) => (
                  <Link href={item.link} key={idx} className={`
                    nav-item-dropdown 
                    ${idx === 0 ? 'first' : idx === (webDropdownItems.length - 1) ? 'last' : ''}
                  `}>
                    {item.name}
                  </Link>
                ))
              }
            </div>
          )}

          <Link className='nav-item' href="/membership">
            Membership
          </Link>
          <Link className="nav-item" href="/weddings">
            Weddings
          </Link>
          <Link href="https://apply.glamsquad.com" legacyBehavior>
            <a target='_blank' className="nav-item last">Become a Pro</a>
          </Link>
        </div>

        <Link href='/'>
          <span className='logo-link-large'>
            <img src='/images/new-gs-wordmark-logo.svg' width="320" height="44" alt='glamsquad logo' />
          </span>
        </Link>

        <div className="right-links">
          {!user ? (
            <Link href="/signin" className='nav-item'>
              Sign In
            </Link>
          ) : (
            <button
              className="services-button"
              onClick={toggleUserDropdown}
              id="user-dropdown-toggle"
            >
              <Text fontSize={14} spacing={0.84} weight={700} margin="0 4px 0 0">
                Hi {user.nameFirst}
              </Text>
              {userDropdownOpen ? <UpArrow /> : <DownArrow />}
            </button>
          )}
          {(!!user && userDropdownOpen) && (
            <div className="user-dropdown " id="user-dropdown-area">
              <Link className='nav-item-dropdown first' href="/account/details">
                My Account
              </Link>
              <Link href="/account/appointments" className='nav-item-dropdown'>
                My Appointments
              </Link>
              {user.isMember && (
                <Link className='nav-item-dropdown' href="/account/membership">
                  Membership
                </Link>
              )}
              <Link className='nav-item-dropdown' href="/account/credits">
                Credits
              </Link>
              <Link className='nav-item-dropdown' href="/account/invite-friends">
                Give $20, Get $20
              </Link>
              <Link legacyBehavior href="https://help.glamsquad.com/en-US">
                <a className="nav-item-dropdown" target='_blank'>Support</a>
              </Link>
              <button
                onClick={signOut}
                className='nav-item-dropdown last'
              >
                Sign Out
              </button>
            </div>
          )}
          <button className="book-button" onClick={bookLink}>
            <Text fontSize={14} spacing={0.84} weight={700} margin="0 4px 0 0">
              Book
            </Text>
          </button>
        </div>

      </NewNavContainerWeb>


    </>
  )
}

export default WebNavBar
