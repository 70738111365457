/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import { Text } from '../../styles/Components'
import { signOut as _signOut } from '../../services/Authenticator'
import PlusIcon from './PlusIcon'
import MinusIcon from './MinusIcon'
import { useUserContext } from '../UserContext'
import Link from 'next/link'

import { NewNavContainerMobile } from './styles'

const MobileNavBar = ({
  bookLink,
  mobileDropdownOpen,
  toggleMobileDropdown,
}) => {
  const [servicesOpen, setServicesOpen] = useState(false)
  const [userOpen, setUserOpen] = useState(false)

  const { user, setUser } = useUserContext()

  const toggleServicesOpen = () => setServicesOpen((o) => !o)
  const toggleUserOpen = () => setUserOpen((o) => !o)

  const signOut = () => {
    _signOut()
    setUser(undefined)
    window.location = '/'
  }

  return (
    <>
      <NewNavContainerMobile>
        <button className="menu-button" onClick={toggleMobileDropdown}>
          <img src="/images/menu-hamburger.svg" width="22" height="22" alt="menu button" />
        </button>
        <Link href="/">
          <span className="logo-link">
            <img src="/images/new-gs-wordmark-logo.svg" width="320" height="44" alt="glamsquad logo" />
          </span>
        </Link>
        <button className="book-button mobile" onClick={bookLink}>
          <Text fontSize={14} spacing={0.84} weight={700}>
            Book
          </Text>
        </button>
      </NewNavContainerMobile>
      <div className={`mobile-dropdown ${mobileDropdownOpen ? 'active' : ''}`}>
        <div
          className={`mobile-nav-content ${mobileDropdownOpen ? '' : 'hide'}`}
        >
          <div className="upper-content">
            <button
              className="inner-dropdown-button"
              onClick={toggleServicesOpen}
            >
              <p>Services</p>
              {servicesOpen ? <MinusIcon /> : <PlusIcon />}
            </button>
            {servicesOpen && (
              <div className="mobile-inner-dropdown">
                <Link href="/book">
                  Book a Service
                </Link>
                <Link href="/how-it-works">
                  How It Works
                </Link>
                <Link href="/services">
                  Our Services
                </Link>
                <Link href="/packages">
                  Packages
                </Link>
                <Link href="/gift-card">
                  Gift Cards
                </Link>
              </div>
            )}
            <Link href="/membership" className='mobile-link'>
              Membership
            </Link>
            <Link className='mobile-link' href="/weddings">
              Weddings
            </Link>
            <Link legacyBehavior href="https://apply.glamsquad.com">
              <a target='_blank' className="mobile-link">Become a Pro</a>
            </Link>
            {!!user && (
              <>
                <button
                  className="inner-dropdown-button"
                  onClick={toggleUserOpen}
                >
                  <p>Hi {user.nameFirst}</p>
                  {userOpen ? <MinusIcon /> : <PlusIcon />}
                </button>
                {userOpen && (
                  <div className="mobile-inner-dropdown">
                    <Link href="/account/details">
                      My Account
                    </Link>
                    <Link href="/account/appointments">
                      My Appointments
                    </Link>
                    {user.isMember && (
                      <Link href="/account/membership" className='nav-item-dropdown'>
                        Membership
                      </Link>
                    )}
                    <Link href="/account/credits">
                      Credits
                    </Link>
                    <Link href="/account/invite-friends">
                      Give $20, Get $20
                    </Link>
                    <Link legacyBehavior href="https://help.glamsquad.com/en-US">
                      <a target='_blank'>Support</a>
                    </Link>
                    <button onClick={signOut}>Sign Out</button>
                  </div>
                )}
              </>
            )}
          </div>
          {!user && (
            <div className="lower-content">
              <Link href="https://help.glamsquad.com/en-US">
                FAQS
              </Link>
              <Link href="signin">
                Sign In
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MobileNavBar
