const enabledStorageModes = { localStorage: false, sessionStorage: false }
/*
 * Used as a fallback for when users have cookies/dom storage
 * disabled.
 */
const createTempStorage = () => {
  let _tempStorage = {}
  return {
    length: Object.keys(_tempStorage).length,
    setItem: (key, value) => (_tempStorage[key] = value),
    getItem: key => _tempStorage[key],
    removeItem: key => delete _tempStorage[key],
    clear: () => (_tempStorage = {}),
    log: () => console.log(_tempStorage)
  }
}

/*
 * tests if localStorage or sessionStorage can be set
 *
 * @param {mode} string
 */
const testStorageMode = mode => {
  try {
    if (mode === 'localStorage') {
      localStorage.setItem('test', 'test')
      localStorage.removeItem('test')
      enabledStorageModes[mode] = true
      return true
    }

    if (mode === 'sessionStorage') {
      sessionStorage.setItem('test', 'test')
      sessionStorage.removeItem('test')
      enabledStorageModes[mode] = true
      return true
    }
  } catch (error) {
    return false
  }
}

/*
 * recieves a preferredMode ( localStorage, sessionStorage)
 * tests if the user allows access to cookies, then the preferredMode
 * if available return storage mode
 *
 * falls back to using a local variable for tempStorage
 *
 * @param {preferredMode} string
 */
const tempStorage = createTempStorage()
export const storageMode = preferredMode => {
  if (typeof navigator === 'undefined') {
    return tempStorage;
  }  
  if (navigator.cookieEnabled) {
    if (enabledStorageModes[preferredMode] || testStorageMode(preferredMode)) {
      return window[preferredMode]
    }
  }
  return tempStorage
}
