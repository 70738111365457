/**
 * authenticator provides functions to login or create an account
 */

import jsSHA from 'jssha'
import { get } from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { me } from './GraphQlClientService'
import { storageMode } from '../helpers'
const _localStorage = storageMode('localStorage')
const _sessionStorage = storageMode('sessionStorage')

const CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID
const CLIENT_SECRET = process.env.NEXT_PUBLIC_CLIENT_SECRET
const STARBASE_URL = process.env.NEXT_PUBLIC_STARBASE_URL

/**
 * authenticate via wordpress 🙀 admin ajax
 *
 * @param {*} facebookId
 * @param {*} facebookToken
 * @returns
 */
export const signInFacebookWeb = async facebookResponse => {
  // {
  //   "name": "Matthew Aldhcjehiiedd Greeneberg",
  //   "id": "109321877354675",
  //   "accessToken": "EAAC0ZCYiPYmIBADJnDnpwx9TRAMU1qc1iKXVZBTtIt0MwSlgFwhFeC1uMzDGisapUWQFZB0sJf2KmXJEiZCHeOpZC975MV1pJUr7H5QWudiGfXocaGzd0aSs94H27x35qeLSa5WxMDlWzZCBtwN8g1Oa5YeDJJ1PJZCg5bXcaGXaOelCNDU2IfXUuPc8L0h5hGcLxs6NuTQdZAz0a9VIZCx3e",
  //   "userID": "109321877354675",
  //   "expiresIn": 3759,
  //   "signedRequest": "qnQ8kxWW2eQH4yTPULD1azA2bKdq64qtsuY-NNmLh5Q.eyJ1c2VyX2lkIjoiMTA5MzIxODc3MzU0Njc1IiwiY29kZSI6IkFRRHM3VHBZUzFMd2JpVGhQN3JmSW93b0dwUUw2SmF4QlVGMTMxaTVIZ3V1azBjQUtaSVVvcWZnc3prUlNOZ3FTRkRYc0dFeGJ3bHhnbWtwTDBMQTNIT2FSaHpVTGdfeldKZlVmNG5OeGQ0ZXJvbGs0Vm5RV3B3U1Y1eXBRbGRTRnNianlDYTJWLWhsb2p3RlQxSUpnUTNYMUYzbmRGYldyRzhZS0xULTA3OEVqX1RpaWE2dG9zUEZMMkdCM1RNc3VvejEwSjcwakM3NktQTlplb1BzRmNmVjdGbHpNYS1FNjdTR1VWQUtuSDJXZWFZOW91TTRGY0ZZbklLSU85TnVibUEyYVpWaVBvLXoxSWNQQl9IRlh4bkxBOHFIdHdXbWZVYl9ocTZ4RDdlWmpsWXBfRElpUVkyODhzRXZEdlMyMlpqSmQ2MTJjd2VNenFBQVpKdFJyTVZOIiwiYWxnb3JpdGhtIjoiSE1BQy1TSEEyNTYiLCJpc3N1ZWRfYXQiOjE1ODM5NDIyNDF9",
  //   "graphDomain": "facebook",
  //   "data_access_expiration_time": 1591718241
  // }

  const { userID: facebookId, accessToken: facebookToken } = facebookResponse

  if (facebookId & facebookToken) {
    throw new Error('Missing required parameter')
  }

  const { access_token: accessToken } = await makeSignedAPICall('POST', '/api/v1/auth/token', {
    grant_type: 'facebook',
    facebookToken
  })
    .catch((err) => {
      console.debug(err)
      return {}
    })

  if (accessToken) {
    _localStorage.setItem('accessToken', accessToken)
    _localStorage.setItem('lastUpdated', moment().unix())

    return me()
  }

  // No account with this facebook, try signup.
  const user = await signUp(
    facebookResponse.email,
    facebookResponse.first_name,
    facebookResponse.last_name,
    undefined,
    uuidv4(),
    undefined,
    facebookId
  )

  if (!user) {
    //real error
    throw new Error('Login failed.')
  }

  return user
}

/**
 * sign into an account and set access token in local storage
 *
 * @param {*} email
 * @param {*} password
 * @returns
 */
export const signIn = async (email, password, rememberAuth) => {
  if (email & password) {
    throw new Error('Enter your email and password.')
  }

  const res = await makeSignedAPICall('POST', '/api/v1/auth/token', {
    username: email.trim().toLowerCase(),
    password: password,
    grant_type: 'password'
  })

  const { access_token: accessToken } = res

  if (rememberAuth) {
    _localStorage.setItem('accessToken', accessToken)
    _localStorage.setItem('lastUpdated', moment().unix())
  }

  _sessionStorage.setItem('accessToken', accessToken)
  _sessionStorage.setItem('lastUpdated', moment().unix())

  return me().catch(() => { })
}

/**
 * create an account
 *
 * @param {*} email
 * @param {*} nameFirst
 * @param {*} nameLast
 * @param {*} phone
 * @param {*} password
 * @param {string} referredByCode
 */
export const signUp = async (
  email,
  nameFirst,
  nameLast,
  phone,
  password,
  referredByCode,
  facebookId
) => {
  await signOut()
  return makeSignedAPICall('POST', '/api/v1/users/', {
    email,
    nameFirst,
    nameLast,
    phone,
    password,
    referredByCode,
    facebookId
  }).then(() => signIn(email, password))
}

/**
 * sends password reset email to given email
 * given POST method will send email to user
 * given PUT will update user with body
 *
 * @param {object} body
 * * @param {string} method
 */
export const resetPasswordEmail = async (method, body) => {
  try {
    await makeSignedAPICall(method, '/api/v1/users/password-reset', body)
  } catch (e) {
    console.error(e)
  }
}

export const resetPassword = async (password, token, isProvider) => {
  try {
    const body = { password }
    // provider endpoint is expecting there to be a key in the body instead of a token
    if (isProvider) {
      body.key = token
    } else {
      body.token = token
    }

    await makeSignedAPICall('PUT', '/api/v1/users/password-reset', body)
  } catch (e) {
    console.log(e)
    throw new Error('Error Changing Password')
  }
}

/**
 * fetches a user by their referralCode
 *
 * @param {string} code
 */
export const findByReferralCode = async code =>
  await makeSignedAPICall('GET', `/api/v1/referrals/${code}/sender`)

/**
 * adds client signature header and makes request
 *
 * @param {*} method
 * @param {*} endpoint
 * @param {*} body
 * @returns
 */
export const makeSignedAPICall = async (method, endpoint, body) => {
  const sha = new jsSHA('SHA-256', 'TEXT')
  sha.setHMACKey(CLIENT_SECRET, 'TEXT')
  sha.update([method, endpoint, JSON.stringify(body)].join('|'))
  const hmac = sha.getHMAC('B64')

  const url = STARBASE_URL + endpoint

  try {
    const res = await axios({
      method,
      url,
      headers: {
        Authorization: 'Glam ' + [CLIENT_ID, hmac].join(':'),
        'Content-Type': 'application/json'
      },

      data: body,
      timeout: 15000,
      responseType: 'json'
    })

    if (res.status > 399) {
      const err = new Error(
        get(res, 'data.errors.0.message') || 'Error making request.'
      )
      err.res = res
      err.body = res.data

      throw err
    }

    return res.data
  } catch (error) {
    console.warn('makeSignedAPICall', error)
    throw error
  }
}

export const signOut = () => {
  _localStorage.removeItem('accessToken')
  _localStorage.removeItem('lastUpdated')
  _localStorage.removeItem('userInfo')
  _sessionStorage.removeItem('accessToken')
  _sessionStorage.removeItem('book')

  return null
}
export const isAuthenticated = () =>
  !!_localStorage.getItem('accessToken') ||
  !!_sessionStorage.getItem('accessToken')

export const exchangeCodeForToken = async (code) => {
  try {
    const { access_token } = await makeSignedAPICall('POST', '/api/v1/auth/token', {
      grant_type: 'authorization_code',
      code: code.replace(/\s/g, '+')
    })

    _localStorage.setItem('accessToken', access_token)
    _localStorage.setItem('lastUpdate', moment().unix())

    _sessionStorage.setItem('accessToken', access_token)
    _sessionStorage.setItem('lastUpdated', moment().unix())

    return me()
  } catch (e) {
    console.error('exchangeCodeForToken', e)
    throw e
  }
}

// sets the access token to the agent parameter and returns the standard me
// used when linking from starbase appointment detail to client survey
// allows agents to answer surveys for a client
export const authenticateAgent = (agent) => {
  try {
    _sessionStorage.setItem('accessToken', agent)
    return me()
  } catch (error) {
    console.error('authenticateAgent', error)
    throw error
  }
}
