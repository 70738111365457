/** 
 * Polyfills: a place to define your polyfills
 */
import { useEffect, useState } from 'react';

const polyfills = [
  // fetch
  {
    test: () => window.fetch,
    fill: () => import('whatwg-fetch'),
  },
  // smoothscroll
  {
    test: () => 'scrollBehavior' in document.documentElement.style,
    fill: () => import('smoothscroll-polyfill').then(smoothscroll => smoothscroll.polyfill())
  },
  // Intl
  {
    test: () => window.Intl,
    fill: () => Promise.resolve()
      .then(() => import('@formatjs/intl-getcanonicallocales/polyfill'))
      .then(() => import('@formatjs/intl-locale/polyfill'))
      .then(() => import('@formatjs/intl-pluralrules/polyfill'))
      .then(() => import('@formatjs/intl-numberformat/polyfill-force'))
      .then(() => import('@formatjs/intl-numberformat/locale-data/en'))
      .then(() => import('@formatjs/intl-datetimeformat/polyfill'))      
      .then(() => import('@formatjs/intl-datetimeformat/locale-data/en'))
  },

  // IntersectionObserver
  {
    test: () => window.IntersectionObserver,
    fill: () => import('intersection-observer')
  },   
]

const Polyfills = ({ fallback = null, children }) => {
  const [completed, setCompleted] = useState(false)

  // test each polyfill and fill the ones which fail the test
  useEffect(() => {
    Promise.all(
      polyfills
        .filter(({ test }) => !test())
        .map(polyfill => polyfill.fill())
    )
      .then(() => setCompleted(true))
  }, [])

  if (typeof window === 'undefined') {
    return children
  }

  return (completed ? children : fallback)
}
export default Polyfills