import * as React from 'react'

const MinusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path fill="#000" d="M2 10.333h20v3.333H2z" />
  </svg>
)
export default MinusIcon
