/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core'
import { gsColors } from './Variables.js'

import BrownLightEot from '../fonts/Brown-Light.eot'
import BrownLightTtf from '../fonts/Brown-Light.ttf'
import BrownLightWoff from '../fonts/Brown-Light.woff'
import BrownLightWoff2 from '../fonts/Brown-Light.woff2'
import BrownRegularEot from '../fonts/Brown-Regular.eot'
import BrownRegularTtf from '../fonts/Brown-Regular.ttf'
import BrownRegularWoff from '../fonts/Brown-Regular.woff'
import BrownRegularWoff2 from '../fonts/Brown-Regular.woff2'
import BrownBoldEot from '../fonts/Brown-Bold.eot'
import BrownBoldTtf from '../fonts/Brown-Bold.ttf'
import BrownBoldWoff from '../fonts/Brown-Bold.woff'
import BrownBoldWoff2 from '../fonts/Brown-Bold.woff2'
import FreightDispProBook from '../fonts/FreightDispProBook.otf'

import EuclidBold from '../fonts/euclid/EuclidCircularA-Bold.otf'
// import EuclidBoldItalic from '../fonts/euclid/EuclidCircularA-BoldItalic.otf'
// import EuclidLight from '../fonts/euclid/EuclidCircularA-light.otf'
// import EuclidLightItalic from '../fonts/euclid/EuclidCircularA-LightItalic.otf'
import EuclidMedium from '../fonts/euclid/EuclidCircularA-Medium.otf'
// import EuclidMediumItalic from '../fonts/euclid/EuclidCircularA-MediumItalic.otf'
import EuclidRegular from '../fonts/euclid/EuclidCircularA-Regular.otf'
// import EuclidRegularItalic from '../fonts/euclid/EuclidCircularA-RegularItalic.otf'
// import EuclidSemiBold from '../fonts/euclid/EuclidCircularA-Semibold.otf'
import EuclidRegularItalic from '../fonts/euclid/EuclidCircularA-RegularItalic.otf'
// import EuclidSemiBoldItalic from '../fonts/euclid/EuclidCircularA-SemiBoldItalic.otf'

import GarnettBold from '../fonts/garnett/Garnett-Bold.otf'
import GarnettRegular from '../fonts/garnett/Garnett-Regular.otf'
import GarnettSemiBold from '../fonts/garnett/Garnett-Semibold.otf'

const GlobalStyles = css`
  scroll-behavior: smooth;

  @font-face {
    font-family: 'Euclid';
    src: url('${EuclidRegular}') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'EuclidItalic';
    src: url('${EuclidRegularItalic}') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Euclid';
    src: url('${EuclidMedium}') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Euclid';
    src: url('${EuclidBold}') format('opentype');
    font-weight: 750;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Garnett';
    src: url('${GarnettRegular}') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Garnett';
    src: url('${GarnettSemiBold}') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Garnett';
    src: url('${GarnettBold}') format('opentype');
    font-weight: 750;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Brown';
    src: url('${BrownLightEot}');
    src: url('${BrownLightEot}?#iefix') format('embedded-opentype'),
      url('${BrownLightWoff2}') format('woff2'),
      url('${BrownLightWoff}') format('woff'),
      url('${BrownLightTtf}') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brown';
    src: url('${BrownRegularEot}');
    src: url('${BrownRegularEot}?#iefix') format('embedded-opentype'),
      url('${BrownRegularWoff2}') format('woff2'),
      url('${BrownRegularWoff}') format('woff'),
      url('${BrownRegularTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brown';
    src: url('${BrownBoldEot}');
    src: url('${BrownBoldEot}?#iefix') format('embedded-opentype'),
      url('${BrownBoldWoff2}') format('woff2'),
      url('${BrownBoldWoff}') format('woff'),
      url('${BrownBoldTtf}') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Freight';
    src: url('${FreightDispProBook}');
    font-display: swap;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;

    .react-datepicker {
      font-family: Brown !important;
    }
  }

  html:focus-within {
    scroll-behavior: smooth;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background-color: ${gsColors.white};
    color: ${gsColors.black};
    font-family: Brown, sans-serif;
    font-weight: normal;
    line-height: 1.625;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  [data-af-custom-fonts="af-creatives-text"] {
      font-family: 'Brown', sans-serif;
  }


  h1,
  h2,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  .show {
    display: block !important;
  }

  .hide {
    display: none !important;
  }

  /* Google Autocomplete */
  .pac-container {
    z-index: 10000 !important;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .booking-flow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .react-datepicker__day--selected {
    background-color: ${gsColors.grape};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
    // transition: background-color 5000s ease-in-out 0s;
  }
`

export default GlobalStyles
