import { useState, useEffect, useRef, useCallback, useReducer } from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { me } from '../services/GraphQlClientService'
import { useRouter } from 'next/router'
import { segmentPageView, storageMode } from '../helpers'
import { getAccessToken, getBanner } from '../services'

/**
 * similar to useState, but returns a function which toggles a boolean
 *
 * @param {boolean} [initialValue=false]
 * @returns [value, toggleFn, setFn]
 */
export const useBooleanState = (initialValue = false) => {
  const [value, setFn] = useState(initialValue)

  return [value, () => setFn(!value), setFn]
}

export const usePageViews = () => {
  const { pathname } = useRouter()
  useEffect(() => {
    segmentPageView(pathname)
  }, [pathname])
}

/**
 *
 * useAction Accepts an action param. The incoming "action" argument to the hook is NOT performed.
 *  It is only stored in the function scope; so that, we can use it when
 *  performing the action using the following function
 *  This function is returned as the second element in the returned array
 * @param {Function} action the function to be called when we call performAction
 * @returns {array} [{ loading, data, error }, performAction]
 */
export const useAction = action => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const performAction = async (...args) => {
    let result
    try {
      setLoading(true)
      setData(null)
      setError(null)
      result = await action(...args)
      setData(result)
    } catch (e) {
      result = e
      setError(e)
    } finally {
      setLoading(false)
    }
    return result
  }
  return [{ loading, data, error }, performAction]
}

/**
 * CustomToggler - Component that
 * @param children
 * @param defaultToggled {boolean} [optional]  toggled's original state, defaults to false
 * @param onToggle {function} [optional] function that gets triggered on Toggle change
 */
export const CustomToggler = ({ children, defaultToggled, onToggle }) => {
  const useToggle = (defaultToggled = false, onToggle) => {
    const onToggleRef = useRef()
    onToggleRef.current = onToggle

    const [toggled, setToggled] = useState(defaultToggled)
    // you can either give it a new toggled state or it'll just toggle to whatever it wasn't
    const toggle = useCallback(
      toggled =>
        setToggled(
          typeof toggled === 'boolean'
            ? toggled
            : previousToggled => !previousToggled
        ),
      [setToggled]
    )

    useEffect(() => {
      if (onToggleRef.current) {
        onToggleRef.current(toggled)
      }
    }, [onToggleRef, toggled])

    return [toggled, toggle]
  }

  return children(useToggle(defaultToggled, onToggle))
}

export const useChangeEventState = initialValue => {
  const [value, setValue] = useState(initialValue)

  const setValueFromEvent = e => setValue(_.get(e, 'target.value', e))

  return [value, setValueFromEvent, setValue]
}

/**
 * returns currently logged in user
 *
 * @returns user
 */
export const useMe = () => {
  const [user, setUser] = useState({})

  // load the current user
  useEffect(() => {
    me().then(setUser)
  }, [])

  return user
}

export const useWindowResize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const eventListener = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', eventListener, { passive: true })

    return () =>
      window.removeEventListener('resize', eventListener, { passive: true })
  }, [])

  return windowWidth
}

/**
 * usePersistedReducer: hook which persists state to sessionStorage and localStorage
 * only reads from specified storageReadType
 *
 * @param {*} reducer: reducer function
 * @param {*} initialState : initial state of reducer
 * @param {*} key: key to save state to in storage
 * @param {*} storageReadType: sessionStorage or localStorage
 * @returns {object} {state, reducerDispatch}
 */
export const usePersistedReducer = (
  reducer,
  initialState,
  key,
  storageReadType = 'sessionStorage'
) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => initialState)

  useEffect(() => {
    const storedState = storageMode(storageReadType).getItem(key)
    if (storedState) {
      try {
        dispatch(JSON.parse(storedState))
      } catch (error) {
        console.error(error)
      }
    }
  }, [key, storageReadType])

  // parse string dateTime because sessionStorage saves in ISO format
  if (typeof state.startDateTime === 'string') {
    state.startDateTime = DateTime.fromISO(state.startDateTime)
  }

  // save to both sessionStorage and localStorage
  // localStorage is used for the resume cart feature
  useEffect(() => {
    if (state.step === 6) {
      storageMode('localStorage').removeItem('book')
      storageMode('sessionStorage').removeItem('book')
    } else {
      storageMode('localStorage').setItem(key, JSON.stringify(state))
      storageMode('sessionStorage').setItem(key, JSON.stringify(state))
    }
  }, [state, key])

  return { state, reducerDispatch: dispatch }
}

/**
 * useMediaQueryMatch: hook which returns true when media query matches
 *
 * @param {*} query
 * @returns
 */
export const useMediaQueryMatch = query => {
  const [isMatch, setIsMatch] = useState(true)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query)

    setIsMatch(mediaQueryList.matches)

    const eventListener = e => setIsMatch(e.matches)

    mediaQueryList.addEventListener('change', eventListener)

    return () => mediaQueryList.removeEventListener('change', eventListener)
  }, [query])

  return isMatch
}

/**
 * useIsDesktop: hooks registers a listener and detects if browser is desktop
 * or mobile size
 *
 * @export
 * @return {*} 
 */
export function useIsDesktop() {
  // desktop media query
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    setIsDesktop(window.innerWidth >= 768)

    const handleResize = () => {
      if (
        (isDesktop && window.innerWidth <= 768) ||
        (!isDesktop && window.innerWidth >= 768)
      ) {
        setIsDesktop(window.innerWidth >= 768)
      }
    }

    window.addEventListener('resize', handleResize, { passive: true })
    return () => window.removeEventListener('resize', handleResize)
  }, [isDesktop])

  return isDesktop
}


/** 
 * useProtectedRoute: checks if user is authenticated, if not reidrects to specified url
 *
*/
export const useProtectedRoute = (redirect) => {
  const isLoggedIn = !!getAccessToken()
  const router = useRouter()

  useEffect(() => {
    if (!isLoggedIn) {
      router.push(redirect)
    }
  }, [isLoggedIn, redirect, router])
}

/**
 * useBanner: fetches dynamic banner strip from Starbase
 * returns banner expiring soonest if multiple timed banners
 * returns default banner if no timed banners available
 * @returns banner object
 */
export const useBanner = () => {
  const [banner, setBanner] = useState(null)
  
  useEffect(() => {
    const bannerLocal = JSON.parse(window?.localStorage?.getItem('GSBanner')) || null

    if (bannerLocal) {
      setBanner(bannerLocal)
    }
  }, [])
  
  useEffect(() => {
    getBanner()
      .then(({ banners, defaultBanner }) => {
        const bannerLocal = window?.localStorage?.getItem('GSBanner') || null

        if (!banners.length && !!defaultBanner) {
          if (!bannerLocal || !!bannerLocal && bannerLocal.id !== defaultBanner.id) {
            setBanner(defaultBanner)
            window.localStorage.setItem('GSBanner', JSON.stringify(defaultBanner))
          }
        } else if (banners.length) {
          if (!bannerLocal || !!bannerLocal && bannerLocal.id !== banners[0].id) {
            setBanner(banners[0])
            window.localStorage.setItem('GSBanner', JSON.stringify(banners[0]))
          }
        } else if (!banners.length && !defaultBanner) {
          window.localStorage.setItem('GSBanner', null)
        }
      })
  }, [])
  return banner
}
